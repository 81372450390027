import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import BlogReplies from "./BlogReplies";
import BlogCommentForm from "./BlogCommentForm";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";

//Mui stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
  commentImage: {
    maxWidth: "100%",
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
  commentData: {
    marginLeft: 20,
  },
  commentBy: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  invisibleSeparatorComment: {
    border: "none",
    backgroundColor: "#fff",
    margin: 10,
  },
  replyDiv: {
    float: "right",
    padding: "10px",
  },
});

class BlogComments extends Component {
  refC = React.createRef();
  state = {
    open: false,
    selectedCommentId: "",
  };
  handleClose = (commentId) => {
    this.setState({ open: false });
    this.setState({ selectedCommentId: commentId });
    if (this.props.commentHandler) {
      this.props.commentHandler(true);
    }
  };
  handleOpen = (commentId) => {
    this.setState({ open: true });
    this.setState({ selectedCommentId: commentId });
    if (this.props.commentHandler) {
      this.props.commentHandler(false);
    }
  };
  handleChange = (someValue) => {
    this.setState({ open: someValue });
  };
  render() {
    const { comments, classes } = this.props;
    return (
      <Grid container>
        <hr className={classes.invisibleSeparatorComment} />
        {this.props.comments &&
          comments.map((comment, index) => {
            const {
              body,
              createdAt,
              name,
              replies,
              blogId,
              commentId,
            } = comment;
            return (
              <Fragment key={createdAt}>
                <Grid item sm={12} xs={12} style={{ backgroundColor: "#fff" }}>
                  <div className={classes.replyDiv}>
                    <Button
                      onClick={() => this.handleOpen(commentId)}
                      color="secondary"
                    >
                      Reply
                    </Button>
                  </div>
                  <Grid container spacing={4}>
                    <Grid item sm={12}>
                      <div className={classes.commentData}>
                        <Typography
                          className={classes.commentBy}
                          variant="subtitle2"
                          color="primary"
                        >
                          {name} SAYS...
                        </Typography>

                        <Typography variant="body1">{body}</Typography>
                        <hr className={classes.invisibleSeparator} />
                        <Typography variant="body2" color="textSecondary">
                          {dayjs(createdAt).format("h:mm a, MMMM DD YYYY")}
                        </Typography>
                      </div>
                      {this.state.open &&
                      this.state.selectedCommentId === commentId ? (
                        <Fragment>
                          <div className={classes.replyDiv}>
                            <Button
                              onClick={() => this.handleClose(commentId)}
                              color="secondary"
                            >
                              Cancel Reply
                            </Button>
                          </div>
                          <BlogCommentForm
                            blogId={blogId}
                            commentId={commentId}
                            open={this.state.open}
                            handler={this.handleChange}
                            refC={this.refC}
                          />
                          <hr className={classes.visibleSeparator} />
                        </Fragment>
                      ) : null}

                      <hr className={classes.invisibleSeparatorComment} />
                      <div ref={this.refC}>
                        <BlogReplies comments={replies} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {index !== comments.length - 1 && (
                  <span className={classes.visibleSeparator} />
                )}
              </Fragment>
            );
          })}
      </Grid>
    );
  }
}

BlogComments.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default withStyles(styles)(BlogComments);
