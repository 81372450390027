import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import RichTextEditor from "../util/RichTextEditor";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { postBlog, clearErrors } from "../redux/actions/dataActions";
import Alert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  progressSpinner: {
    position: "absolute",
  },
  closeButton: {
    position: "absolute",
    left: "90%",
    top: "1%",
  },
  texteditor: {
    marginTop: 30,
  },
});
class createBlog extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      body: "",
      intro: "",
      files: [],
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ body: "", errors: {} });
    }
  }
  handleEditorChange = (childValue) => {
    this.setState({ body: childValue });
  };

  onFilesChange = (files) => {
    this.setState({ files: [files] });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const newBlogData = {
      title: this.state.title,
      intro: this.state.intro,
      body: this.state.body,
      files: this.state.files,
    };
    this.props.postBlog(newBlogData, this.props.history);
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;

    const { errors } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
          <form onSubmit={this.handleSubmit}>
            {(errors.error === "Unauthorized" ||
              errors.code === "auth/id-token-expired") && (
              <div className={classes.root}>
                <Alert severity="error">
                  Session expired! Please login again.
                </Alert>
              </div>
            )}
            <TextField
              id="title"
              name="title"
              type="text"
              label="Title"
              className={classes.textField}
              value={this.state.title}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              name="intro"
              type="text"
              label="Summary!!"
              multiline
              rows="2"
              placeholder="Write summary about your blog"
              className={classes.textField}
              onChange={this.handleChange}
              fullWidth
            />
            {errors.body && (
              <Typography variant="body2" className={classes.customError}>
                {errors.body}
              </Typography>
            )}
            <div className={classes.texteditor}>
              <RichTextEditor
                placeholder="Body"
                onChange={this.handleEditorChange}
                onFilesChange={this.onFilesChange}
                value={this.state.body || ""}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
              disabled={loading}
            >
              Create
              {loading && (
                <CircularProgress
                  size={30}
                  className={classes.progressSpinner}
                />
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }
}

createBlog.propTypes = {
  postBlog: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

export default connect(mapStateToProps, { postBlog, clearErrors })(
  withStyles(styles)(createBlog)
);
