import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// MUI Stuff
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
// Redux stuff
import { connect } from "react-redux";
import { submitBlogComment } from "../../redux/actions/dataActions";
import { submitBlogReply } from "../../redux/actions/dataActions";

const styles = (theme) => ({
  ...theme.spreadThis,
  progressSpinner: {
    position: "absolute",
    color: "#fff",
  },
  commentButton: {
    position: "relative",
    float: "center",
    marginTop: 10,
  },
});

class BlogCommentForm extends Component {
  state = {
    body: "",
    email: "",
    name: "",
    website: "",
    errors: {},
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ body: "" });
      this.setState({ name: "" });
      this.setState({ email: "" });
      this.setState({ website: "" });
      this.setState({ errors: "" });
      if (this.props.handler) {
        this.props.handler(false);
      }
      if (this.props.refB) {
        window.scrollTo(0, this.props.refB.current.offsetTop);
      }
      if (this.props.refC) {
        window.scrollTo(0, this.props.refC.current.offsetTop);
      }
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const newCommentData = {
      name: this.state.name,
      email: this.state.email,
      body: this.state.body,
      website: this.state.website,
    };
    if (this.props.commentId) {
      this.props.submitBlogReply(
        this.props.blogId,
        this.props.commentId,
        newCommentData
      );
    } else {
      this.props.submitBlogComment(this.props.blogId, newCommentData);
      this.props.commentMainHandler(true);
    }
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const errors = this.state.errors;

    const commentFormMarkup = (
      <Grid
        item
        sm={12}
        style={{ textAlign: "center", backgroundColor: "#fff" }}
      >
        <form noValidate onSubmit={this.handleSubmit}>
          <TextField
            name="body"
            type="text"
            label={this.props.commentId ? "Leave a reply" : "Leave a comment"}
            multiline
            rows="5"
            error={errors.comment ? true : false}
            helperText={errors.comment}
            value={this.state.body}
            onChange={this.handleChange}
            fullWidth
            className={classes.textField}
            variant="filled"
          />
          <br />
          <br />
          <br />
          <TextField
            id="name"
            name="name"
            type="text"
            label="Name"
            className={classes.textField}
            helperText={errors.name}
            error={errors.name ? true : false}
            value={this.state.name}
            onChange={this.handleChange}
            fullWidth
            variant="filled"
          />

          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            className={classes.textField}
            helperText={errors.email}
            error={errors.email ? true : false}
            value={this.state.email}
            onChange={this.handleChange}
            fullWidth
            variant="filled"
          />
          <TextField
            id="website"
            name="website"
            type="text"
            label="Website"
            className={classes.textField}
            helperText={errors.website}
            error={errors.website ? true : false}
            value={this.state.website}
            onChange={this.handleChange}
            fullWidth
            variant="filled"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.commentButton}
            disabled={loading}
          >
            {this.props.commentId ? "Post a reply" : "Post a comment"}
            {loading && (
              <CircularProgress size={30} className={classes.progressSpinner} />
            )}
          </Button>
        </form>
      </Grid>
    );
    return commentFormMarkup;
  }
}

BlogCommentForm.propTypes = {
  submitBlogComment: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  blogId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

export default connect(mapStateToProps, { submitBlogComment, submitBlogReply })(
  withStyles(styles)(BlogCommentForm)
);
