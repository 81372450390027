import {
  SET_SCREAMS,
  SET_BLOGS,
  SET_LATEST_BLOGS,
  LIKE_SCREAM,
  UNLIKE_SCREAM,
  LOADING_DATA,
  DELETE_SCREAM,
  DELETE_BLOG,
  POST_SCREAM,
  POST_BLOG,
  POST_CONTACT,
  SET_SCREAM,
  SET_BLOG,
  SUBMIT_COMMENT,
  SUBMIT_BLOG_COMMENT,
  SUBMIT_BLOG_REPLY,
  SET_GALLERY_IMAGES,
} from "../types";

const initialState = {
  screams: [],
  blogs: [],
  latestblogs: [],
  scream: {},
  blog: {},
  contact: {},
  loading: false,
  images: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_SCREAMS:
      return {
        ...state,
        screams: action.payload,
        loading: false,
      };
    case SET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
        loading: false,
      };
    case SET_LATEST_BLOGS:
      return {
        ...state,
        latestblogs: action.payload,
        loading: false,
      };
    case SET_SCREAM:
      return {
        ...state,
        scream: action.payload,
      };
    case SET_BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case LIKE_SCREAM:
    case UNLIKE_SCREAM:
      let index = state.screams.findIndex(
        (scream) => scream.screamId === action.payload.screamId
      );
      state.screams[index] = action.payload;

      if (state.scream.screamId === action.payload.screamId) {
        state.scream = { ...state.scream, ...action.payload };
      }
      return {
        ...state,
      };
    case DELETE_SCREAM:
      let delIndex = state.screams.findIndex(
        (scream) => scream.screamId === action.payload
      );
      state.screams.splice(delIndex, 1);
      return {
        ...state,
      };
    case DELETE_BLOG:
      let delBlogIndex = state.blogs.findIndex(
        (blog) => blog.blogId === action.payload
      );
      state.blogs.splice(delBlogIndex, 1);
      return {
        ...state,
      };
    case POST_SCREAM:
      return {
        ...state,
        screams: [action.payload, ...state.screams],
      };
    case POST_BLOG:
      return {
        ...state,
        blogs: [action.payload, ...state.blogs],
      };
    case POST_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case SUBMIT_COMMENT:
      if (state.scream.screamId === action.payload.screamId) {
        state.scream.commentCount = state.scream.commentCount + 1;
      }
      return {
        ...state,
        scream: {
          ...state.scream,
          comments: [action.payload, ...state.scream.comments],
        },
      };
    case SUBMIT_BLOG_COMMENT:
      if (state.blog.blogId === action.payload.blogId) {
        state.blog.commentCount = state.blog.commentCount + 1;
      }
      return {
        ...state,
        blog: {
          ...state.blog,
          comments: [action.payload, ...state.blog.comments],
        },
      };
    case SUBMIT_BLOG_REPLY:
      if (state.blog.blogId === action.payload.blogId) {
        state.blog.commentCount = state.blog.commentCount + 1;
      }

      let commentIndex = state.blog.comments.findIndex(
        (comment) => comment.commentId === action.payload.commentIsChidOfDocid
      );
      if (state.blog.comments[commentIndex].replies) {
        state.blog.comments[commentIndex].replies.push(action.payload);
      } else {
        var comments = [];
        var replies = [];
        replies.push(action.payload);
        comments = state.blog.comments;
        comments[commentIndex].replies = replies;

        console.log(comments);
        state.blog.comments = comments;
      }

      return {
        ...state,
      };
    case SET_GALLERY_IMAGES:
      return {
        ...state,
        images: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
