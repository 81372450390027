import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./fonts/Raleway-SemiBold.ttf";
import "./fonts/JosefinSlab-SemiBold.ttf";
import "./fonts/NunitoSans-Regular.ttf";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";
import jwtDecode from "jwt-decode";
import { Helmet } from "react-helmet";

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";
//Components
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import AuthRoute from "./util/AuthRoute";

//Pages
import blog from "./pages/blog";
import login from "./pages/login";
import signup from "./pages/signup";
import user from "./pages/user";
import createBlog from "./pages/createBlog";
import blogPage from "./pages/blogPage";
import gallery from "./pages/gallery";
import updateBlog from "./pages/updateBlog";
import contact from "./pages/contact";

import axios from "axios";

const theme = createMuiTheme(themeFile);
const seo = {
  title: "BaniCreations",
  description:
    "BaniCreations is a blog which include design food, travel and technology.",
  url: "https://banicreations.com/",
};

axios.defaults.baseURL = "https://creations-9nk7.onrender.com/";

const token = localStorage.FBIdToken;

if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}
class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Router>
            <Navbar />
            <div className="container">
              <Helmet
                title={seo.title}
                meta={[
                  {
                    name: "description",
                    property: "og:description",
                    content: seo.description,
                  },
                  { property: "og:title", content: seo.title },
                  { property: "og:url", content: seo.url },
                ]}
              />
              <Switch>
                <Route exact path="/" component={gallery} />
                <AuthRoute exact path="/login" component={login} />
                <AuthRoute exact path="/signup" component={signup} />
                <Route exact path="/blog/create" component={createBlog} />
                <Route exact path="/blog/:blogId" component={blogPage} />
                <Route exact path="/blog/edit/:blogId" component={updateBlog} />
                <Route exact path="/contact" component={contact} />
                <Route
                  exact
                  path="/users/:handle/scream/:screamId"
                  component={user}
                />
                <Route exact path="/blog" component={blog} />
              </Switch>
            </div>
            <Footer />
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
