import NunitoSans from "../fonts/NunitoSans-Regular.ttf";
const nunitosans = {
  fontFamily: "NunitoSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('NunitoSans'),
    local('NunitoSans-Regular'),
    url(${NunitoSans}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
export default {
  typography: {
    fontFamily: "NunitoSans, Arial",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [nunitosans],
      },
    },
    MuiMenuItem: {
      root: {
        "&&:hover": {
          backgroundColor: "#CDB8FF",
          color: "#000",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#cde9bf",
      main: "#9c64fb",
      dark: "#333",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#333",
      main: "#333",
      dark: "#333",
      contrastText: "#fff",
    },
  },
  spreadThis: {
    typography: {
      useNextVariants: true,
    },
    form: {
      textAlign: "center",
    },
    image: {
      margin: "10px auto 10px auto",
      maxWidth: 50,
      maxHeight: 50,
    },
    pageTitle: {
      margin: "10px auto 10px auto",
    },
    textField: {
      margin: "10px auto 10px auto",
    },
    button: {
      marginTop: 20,
      position: "relative",
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10,
    },
    progress: {
      position: "absolute",
    },
    invisibleSeparator: {
      border: "none",
      margin: 4,
    },
    visibleSeparator: {
      width: "100%",
      backgroundColor: "#fff",
      marginBottom: "5px",
      borderBottom: "0px Black",
    },
    buttons: {
      textAlign: "center",
      "& a": {
        margin: "20px 10px",
      },
    },
    paper: {
      padding: "10px",
      width: "250px",
      maxWidth: "250px" /* or whatever width you want. */,
      display: "inline-block",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    paperBurger: {
      padding: "10px",
      width: "180px",
      maxWidth: "180px" /* or whatever width you want. */,
      display: "inline-block",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      top: 0,
      left: 0,
    },
    imageWrapper: {
      textAlign: "center",
      padding: 0,
      marginTop: 0,
      float: "center",
      position: "relative",
    },
    buttonWrapper: {
      textAlign: "right",
      padding: 0,
      marginTop: 0,
      float: "center",
      marginRight: "50px",
      position: "relative",
    },
    profileImage: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      maxWidth: "100%",
      borderRadius: "50%",
    },
    profile: {
      "& .profile-details": {
        textAlign: "center",
        "& span, svg": {
          verticalAlign: "top",
        },
        "& a": {
          color: "#593650",
        },
      },
      "& hr": {
        border: "none",
        margin: "0 0 10px 0",
      },
    },
  },
};
