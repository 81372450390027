import React, { Component } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import MyButton from "../../util/MyButton";
import DeleteScream from "./DeleteScream";
import LikeButton from "./LikeButton";

//Mui imports
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

//Icons
import ChatIcon from "@material-ui/icons/Chat";

//Redux
import { connect } from "react-redux";

const styles = {
  card: {
    position: "relative",
    display: "flex",
    marginBottom: 20,
  },
  image: {
    minHeight: 200,
  },
  mainPic: {
    width: 300,
    height: 200,
    marginBottom: 0,
    padding: 0,
  },
  content: {
    padding: 10,
    objectFit: "cover",
  },
  dialogButton: {
    left: "80%",
    margin: 0,
    position: "absolute",
    height: "20px",
    bottom: "15%",
    display: "flex",
  },
  deleteButton: {
    left: "80%",
    margin: 0,
    position: "absolute",
    height: "20px",
    top: "10%",
    display: "flex",
  },
  media: {
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "red[500]",
  },
};

class Scream extends Component {
  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      scream: {
        body,
        createdAt,
        userHandle,
        screamId,
        likeCount,
        commentCount,
      },
      user: {
        authenticated,
        credentials: { handle },
      },
    } = this.props;

    const deleteButton =
      authenticated && userHandle === handle ? (
        <DeleteScream screamId={screamId} />
      ) : null;
    return (
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              Trip that you’ll never ever forget
            </Typography>
          }
          subheader={
            <Typography variant="subtitle2" gutterBottom>
              by {userHandle} {dayjs(createdAt).format("MMMM DD YYYY")}
            </Typography>
          }
        />
        <CardMedia
          className={classes.image}
          image="https://firebasestorage.googleapis.com/v0/b/socialapp-cba0a.appspot.com/o/europe.jpg?alt=media&token=14f4effc-d866-4c5f-a7df-c5e050379a61"
          title="Profile image"
        />
        <CardContent className={classes.content}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
          <LikeButton screamId={screamId} />
          <span>{likeCount} Likes</span>
          <MyButton tip="comments">
            <ChatIcon color="primary" />
          </MyButton>
          <span>{commentCount} comments</span>
          <div className={classes.deleteButton}>{deleteButton}</div>
        </CardContent>
      </Card>
    );
  }
}

Scream.propTypes = {
  user: PropTypes.object.isRequired,
  scream: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withStyles(styles)(Scream));
