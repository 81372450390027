import React, { Component, Fragment } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import withStyles from "@material-ui/core/styles/withStyles";

//Mui import
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

const styles = (theme) => ({
  ...theme.spreadThis,
  image: {
    objectFit: "contain",
    height: 480,
    width: "100%",
    backgroundColor: "#fff",
  },
});

class GalleryImage extends Component {
  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      image: { imageDescription, createdAt, galleryImage, userHandle, imageId },
    } = this.props;

    return (
      <Fragment>
        <GridListTile
          key={imageId}
          style={{
            ...this.props.style,
            height: "551px",

            padding: "30px",
          }}
        >
          <img src={galleryImage} alt={imageId} className={classes.image} />
          <GridListTileBar
            title={imageDescription}
            subtitle={
              <span>
                by: {userHandle} on {dayjs(createdAt).format("MMMM DD YYYY")}
              </span>
            }
          />
        </GridListTile>
      </Fragment>
    );
  }
}

export default withStyles(styles)(GalleryImage);
