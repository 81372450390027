import React, { Component } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import DeleteBlog from "./DeleteBlog";

//Mui imports
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";

//Icons

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

//Redux
import { connect } from "react-redux";

const styles = (theme) => ({
  ...theme.spreadThis,
  card: {
    position: "relative",
    display: "flex",
    marginBottom: 20,
  },
  image: {
    objectFit: "cover",
    height: 400,
    width: "100%",
    backgroundColor: "#fff",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 400,
    fontSize: "20pt",
    backgroundColor: "#000",
    color: "#fff",
  },
  content: {
    padding: 10,
    objectFit: "cover",
    fontFamily: "raleway",
  },
});

class Blog extends Component {
  state = {
    currentImage: 0,
    blogFiles: [],
  };

  switchImage = () => {
    if (this.state.currentImage < this.state.blogFiles.length - 1) {
      this.setState({
        currentImage: 0,
      });
    } else {
      this.setState({
        currentImage: 0,
      });
    }
    return this.currentImage;
  };

  componentDidMount() {
    const {
      blog: { blogFiles },
    } = this.props;
    var Files = blogFiles;

    if (Files === undefined) {
    } else {
      let imageFiles = Files.map(function (filePath) {
        var ext = filePath.split("?")[0].split("#")[0].split(".").pop();

        if (ext === "jpg" || ext === "JPG" || ext === "png" || ext === "jpeg") {
          return filePath;
        } else {
          return null;
        }
      });
      this.setState({
        blogFiles: imageFiles,
      });
    }
    setInterval(this.switchImage, 5000);
  }

  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      blog: { createdAt, userHandle, blogId, title, intro },
      user: {
        authenticated,
        credentials: { handle },
      },
    } = this.props;

    const deleteButton =
      authenticated && userHandle === handle ? (
        <DeleteBlog blogId={blogId} />
      ) : null;

    const editButton =
      authenticated && userHandle === handle ? (
        <Tooltip title="Edit Blog" placement="top">
          <Link to={`/blog/edit/${blogId}`}>
            <IconButton aria-label="share" padding="none">
              <EditIcon />
            </IconButton>
          </Link>
        </Tooltip>
      ) : null;

    return (
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              {title}
            </Typography>
          }
          subheader={
            <Typography variant="subtitle2" gutterBottom>
              by {userHandle} {dayjs(createdAt).format("MMMM DD YYYY")}
            </Typography>
          }
        />
        {this.state.blogFiles[this.state.currentImage] ? (
          <Link to={`/blog/${blogId}`}>
            <div>
              <img
                src={this.state.blogFiles[this.state.currentImage]}
                className={classes.image}
                alt="blogImage"
              />
            </div>
          </Link>
        ) : (
          <Link to={`/blog/${blogId}`}>
            <div className={classes.box}>{title}</div>
          </Link>
        )}
        <CardContent className={classes.content}>
          <Typography variant="body2" color="textSecondary">
            {intro}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <div>{deleteButton}</div>
          <div>{editButton}</div>
        </CardActions>
      </Card>
    );
  }
}

Blog.propTypes = {
  user: PropTypes.object.isRequired,
  blog: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withStyles(styles)(Blog));
