import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import MyButton from "../../util/MyButton";

import Profile from "../../components/profile/Profile";

//Icons

import HomeIcon from "@material-ui/icons/Home";
import withWidth from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import MenuList from "@material-ui/core/MenuList";
import Drawer from "@material-ui/core/Drawer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logo from "../../images/logo.svg";
//import Notifications from "@material-ui/icons/Notifications";

//MUI stuff

import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    fontFamily: "raleway,sans-serif",
    color: "#fff",
    fontWeight: "400",
    fontSize: ".8em",
    display: "inline-block",
    verticalAlign: "baseline",
    height: "64px",
    padding: "23px 35px 0",
  },
  grow: {
    flexGrow: 1,
  },
  svgLogo: {
    maxHeight: "30px",
    paddingRight: "0px",
    margin: 0,
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      maxHeight: "40px",
      paddingRight: "10px",
    },
  },
  sectionDesktop: {
    paddingRight: "20px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  profile: {
    position: "absolute",
    display: "flex",
    zIndex: 30,
    top: 60,
    right: 0,
  },
  menu: {
    position: "fixed",
    display: "flex",
    zIndex: 1,
    left: 0,
    top: 0,
  },
});

class Navbar extends Component {
  state = {
    anchorEl: null,
    showProfile: false,
    hamburgerMenu: false,
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.target });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ anchorEl: null });
    }
  }

  handleClickAway = (e) => {
    if (e.target.name === "bio") return;
    if (e.target.name === "website") return;
    if (e.target.name === "location") return;
    this.setState({ showProfile: false });
  };
  render() {
    const { authenticated, classes, width } = this.props;
    const anchorEl = this.state.anchorEl;
    const { showProfile } = this.state;
    let showHamburger = width === "xs" || width === "sm" ? true : false;
    const Logged = (props) => (
      <Fragment>
        <Link to="/">
          <MyButton tip="Home">
            <HomeIcon />
          </MyButton>
        </Link>
        <Link to="/blog">
          <MyButton tip="Blog">
            <ChatBubble />
          </MyButton>
        </Link>
        <Link to="/contact">
          <MyButton tip="Contact">
            <MailIcon />
          </MyButton>
        </Link>
      </Fragment>
    );
    const NotLogged = (props) => (
      <Fragment>
        <Button
          color="inherit"
          component={Link}
          to="/"
          className={classes.button}
        >
          Home
        </Button>

        <Button
          color="inherit"
          component={Link}
          to="/blog"
          className={classes.button}
        >
          BLOG
        </Button>
        <Button
          color="inherit"
          component={Link}
          to="/contact"
          className={classes.button}
        >
          CONTACT
        </Button>
      </Fragment>
    );
    const Hamburger = (props) => (
      <Fragment>
        <Tooltip placement="top" title="Menu">
          <IconButton
            aria-controls={anchorEl ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Drawer
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
        >
          <ClickAwayListener onClickAway={this.handleMenuClose}>
            <MenuList
              autoFocusItem={Boolean(anchorEl)}
              id="menu-list-grow"
              onKeyDown={this.handleMenuClose}
            >
              <MenuItem
                component={Link}
                to={`/`}
                onClick={this.handleMenuClose}
              >
                <ListItemIcon color="inherit">
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="HOME" />
              </MenuItem>

              <MenuItem
                component={Link}
                to={`/blog`}
                onClick={this.handleMenuClose}
              >
                <ListItemIcon>
                  <ChatBubble />
                </ListItemIcon>
                <ListItemText primary="BLOG" />
              </MenuItem>
              <MenuItem
                component={Link}
                to={`/contact`}
                onClick={this.handleMenuClose}
              >
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <ListItemText primary="CONTACT" />
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Drawer>
      </Fragment>
    );
    return (
      <Fragment>
        <AppBar position="fixed" elevation={0}>
          <ToolBar
            className="nav-container"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {showHamburger ? (
              <Hamburger />
            ) : authenticated ? (
              <Logged />
            ) : (
              <NotLogged />
            )}
            <div className={classes.grow} />

            <img src={logo} alt="logo" className={classes.svgLogo} />

            <div className={classes.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={() => this.setState({ showProfile: !showProfile })}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </ToolBar>
        </AppBar>
        {showProfile && (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.profile}>
              <Profile />
            </div>
          </ClickAwayListener>
        )}
      </Fragment>
    );
  }
}

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

export default connect(mapStateToProps)(
  compose(withStyles(styles), withWidth())(Navbar)
);
