import {
  SET_SCREAMS,
  SET_BLOGS,
  SET_LATEST_BLOGS,
  LOADING_DATA,
  LIKE_SCREAM,
  UNLIKE_SCREAM,
  DELETE_SCREAM,
  DELETE_BLOG,
  LOADING_UI,
  CLEAR_ERRORS,
  POST_SCREAM,
  POST_BLOG,
  POST_CONTACT,
  SET_ERRORS,
  SET_SCREAM,
  SET_BLOG,
  STOP_LOADING_UI,
  SUBMIT_COMMENT,
  SUBMIT_BLOG_COMMENT,
  SUBMIT_BLOG_REPLY,
  SET_GALLERY_IMAGES,
} from "../types";
import axios from "axios";

//Get all screams
export const getScreams = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/screams")
    .then((res) => {
      dispatch({
        type: SET_SCREAMS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_SCREAMS,
        payload: [],
      });
    });
};

//Get all blogs
export const getBlogs = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/blogs")
    .then((res) => {
      dispatch({
        type: SET_BLOGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_BLOGS,
        payload: [],
      });
    });
};

//Get all blogs
export const getLatestBlogs = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/latestblogs")
    .then((res) => {
      dispatch({
        type: SET_LATEST_BLOGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_LATEST_BLOGS,
        payload: [],
      });
    });
};

export const getScream = (screamId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/scream/${screamId}`)
    .then((res) => {
      dispatch({
        type: SET_SCREAM,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => console.log(err));
};

//Get a Blog
export const getBlog = (blogId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/blog/${blogId}`)
    .then((res) => {
      dispatch({
        type: SET_BLOG,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => console.log(err));
};

//Edit Blog

export const editBlog = (userDetails, blogId, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/blog/${blogId}`, userDetails)
    .then(() => {
      dispatch(getBlogData(blogId));
      history.push("/blog");
    })
    .catch((err) => console.log(err));
};

export const getBlogData = (blogId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/blog/${blogId}`)
    .then((res) => {
      dispatch({
        type: SET_BLOG,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

//Post a scream

export const postScream = (newScream) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/scream", newScream)
    .then((res) => {
      dispatch({
        type: POST_SCREAM,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Post a blog

export const postBlog = (newBlog, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/blog", newBlog)
    .then((res) => {
      dispatch({
        type: POST_BLOG,
        payload: res.data,
      });
      dispatch(clearErrors());
      history.push("/blog");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Post a contact

export const postContact = (newContact) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/contact", newContact)
    .then((res) => {
      dispatch({
        type: POST_CONTACT,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Like a scream
export const likeScream = (screamId) => (dispatch) => {
  axios
    .get(`/scream/${screamId}/like`)
    .then((res) => {
      dispatch({
        type: LIKE_SCREAM,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Unlike a scream
export const unlikeScream = (screamId) => (dispatch) => {
  axios
    .get(`/scream/${screamId}/unlike`)
    .then((res) => {
      dispatch({
        type: UNLIKE_SCREAM,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Submit a comment

export const submitComment = (screamId, commentData) => (dispatch) => {
  axios
    .post(`/scream/${screamId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Submit a Blog comment

export const submitBlogComment = (blogId, commentData) => (dispatch) => {
  axios
    .post(`/blog/${blogId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_BLOG_COMMENT,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const submitBlogReply = (blogId, commentId, commentData) => (
  dispatch
) => {
  axios
    .post(`/blog/${blogId}/${commentId}/comment`, commentData)
    .then((res) => {
      dispatch({
        type: SUBMIT_BLOG_REPLY,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Delete Scream

export const deleteScream = (screamId) => (dispatch) => {
  axios
    .delete(`/scream/${screamId}`)
    .then(() => {
      dispatch({ type: DELETE_SCREAM, payload: screamId });
    })
    .catch((err) => console.log(err));
};

//Delete Blog

export const deleteBlog = (blogId) => (dispatch) => {
  axios
    .delete(`/blog/${blogId}`)
    .then(() => {
      dispatch({ type: DELETE_BLOG, payload: blogId });
    })
    .catch((err) => console.log(err));
};

export const getUserData = (userHandle) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then((res) => {
      dispatch({
        type: SET_SCREAMS,
        payload: res.data.screams,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SCREAMS,
        payload: null,
      });
    });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

//Get all images
export const getGalleryImages = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/gallery/images")
    .then((res) => {
      dispatch({
        type: SET_GALLERY_IMAGES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_GALLERY_IMAGES,
        payload: [],
      });
    });
};
