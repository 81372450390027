import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { postContact, clearErrors } from "../redux/actions/dataActions";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: "relative",
    float: "left",
    marginTop: 10,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  progressSpinner: {
    position: "absolute",
  },
});

class contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      body: "",
      sucess: false,
      showSucess: false,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (
      !nextProps.UI.errors &&
      !nextProps.UI.loading &&
      this.state.showSucess
    ) {
      this.setState({
        body: "",
        email: "",
        name: "",
        sucess: true,
        errors: {},
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const newContactData = {
      name: this.state.name,
      email: this.state.email,
      body: this.state.body,
    };

    this.props.postContact(newContactData);
    this.setState({
      showSucess: true,
    });
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    let contactMarkup = !this.state.sucess ? (
      <Fragment>
        <Typography variant="h4" className={classes.pageTitle}>
          Contact
        </Typography>
        <form noValidate onSubmit={this.handleSubmit}>
          <TextField
            id="name"
            name="name"
            type="text"
            label="Name"
            className={classes.textField}
            helperText={errors.name}
            error={errors.name ? true : false}
            value={this.state.name}
            onChange={this.handleChange}
            fullWidth
          />

          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            className={classes.textField}
            helperText={errors.email}
            error={errors.email ? true : false}
            value={this.state.email}
            onChange={this.handleChange}
            fullWidth
          />
          <br />
          <br />
          <br />

          <TextField
            name="body"
            type="text"
            label="Message"
            multiline
            rows="5"
            placeholder="Message"
            className={classes.textField}
            value={this.state.body}
            helperText={errors.body}
            error={errors.body ? true : false}
            onChange={this.handleChange}
            variant="outlined"
            fullWidth
          />

          {errors.general && (
            <Typography variant="body2" className={classes.customError}>
              {errors.general}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={loading}
          >
            Send
            {loading && (
              <CircularProgress size={30} className={classes.progress} />
            )}
          </Button>
        </form>
      </Fragment>
    ) : (
      <Fragment>
        <Typography variant="h6" className={classes.pageTitle}>
          Thank you! for contacting. I'll get back to you ASAP
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          Go Home
        </Button>
      </Fragment>
    );

    return (
      <Grid container className={classes.form} spacing={4}>
        <Grid item sm />
        <Grid item sm>
          <div style={{ width: "90%", margin: "3rem auto" }}>
            {contactMarkup}
          </div>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

contact.propTypes = {
  postContact: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

export default connect(mapStateToProps, { postContact, clearErrors })(
  withStyles(styles)(contact)
);
