import React, { Component } from "react";
import { Link } from "react-router-dom";

//Mui imports
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

//Redux
import { connect } from "react-redux";

const styles = (theme) => ({
  ...theme.spreadThis,
  content: {
    padding: 10,
    objectFit: "cover",
    fontFamily: "raleway",
  },
  BorderTop: {
    borderTop: "2px solid #9c64fb",
  },
  commentCount: {
    fontSize: "2.0rem",
    lineHeight: "2.8rem",
    fontWeight: 300,
    color: "#9c64fb",
    margin: "0px",
  },
});

class LatestBlog extends Component {
  render() {
    const {
      classes,
      blog: { title, blogId },
      index,
    } = this.props;

    return (
      <ListItem alignItems="flex-start">
        <ListItemIcon className={classes.commentCount}>{index}</ListItemIcon>
        <Link to={`/blog/${blogId}`}>
          <ListItemText id={blogId} secondary={title} />
        </Link>
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(withStyles(styles)(LatestBlog));
