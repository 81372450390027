import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import ImageResize from "quill-image-resize";

const QuillClipboard = Quill.import("modules/clipboard");

class Clipboard extends QuillClipboard {
  getMetaTagElements = (stringContent) => {
    const el = document.createElement("div");
    el.innerHTML = stringContent;
    return el.getElementsByTagName("meta");
  };

  async onPaste(e) {
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = await clipboardData.getData("Text");

    const urlMatches = pastedData.match(/\b(http|https)?:\/\/\S+/gi) || [];
    if (urlMatches.length > 0) {
      e.preventDefault();
      urlMatches.forEach((link) => {
        axios
          .get(link)
          .then((payload) => {
            // let title, image, url, description;
            let title, image, url;
            for (let node of this.getMetaTagElements(payload)) {
              if (node.getAttribute("property") === "og:title") {
                title = node.getAttribute("content");
              }
              if (node.getAttribute("property") === "og:image") {
                image = node.getAttribute("content");
              }
              if (node.getAttribute("property") === "og:url") {
                url = node.getAttribute("content");
              }
              // if (node.getAttribute("property") === "og:description") {
              //     description = node.getAttribute("content");
              // }
            }

            const rendered = `<a href=${url} target="_blank"><div><img src=${image} alt=${title} width="20%"/><span>${title}</span></div></a>`;

            let range = this.quill.getSelection();
            let position = range ? range.index : 0;
            this.quill.pasteHTML(position, rendered, "silent");
            this.quill.setSelection(position + rendered.length);
          })
          .catch((error) => console.error(error));
      });
    } else {
      super.onPaste(e);
    }
  }
}
Quill.register("modules/clipboard", Clipboard, true);
Quill.register("modules/imageResize", ImageResize, true);

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const imgTag = super.create();
    imgTag.setAttribute("src", value.src);
    imgTag.setAttribute("alt", value.alt);
    imgTag.setAttribute("style", value.style);
    imgTag.setAttribute("width", value.width);
    imgTag.setAttribute("height", value.height);

    //imgTag.setAttribute("width", "100%");
    return imgTag;
  }

  static value(node) {
    return {
      src: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
      style: node.getAttribute("style"),
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);

const Parchment = Quill.import("parchment");

class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"],
});

Quill.register(IndentStyle, true);

// Fonts
let Font = Quill.import("formats/font");
// We do not add Sans Serif since it is the default
Font.whitelist = ["raleway", "josefinslab", "nunitosans"];
Quill.register(Font, true);
//Fonts end

class VideoBlot extends BlockEmbed {
  static create(value) {
    if (value && value.src) {
      const videoTag = super.create();
      videoTag.setAttribute("src", value.src);
      videoTag.setAttribute("title", value.title);
      videoTag.setAttribute("width", "100%");
      videoTag.setAttribute("controls", "");

      return videoTag;
    } else {
      const iframeTag = document.createElement("iframe");
      iframeTag.setAttribute("src", value);
      iframeTag.setAttribute("frameborder", "0");
      iframeTag.setAttribute("allowfullscreen", true);
      iframeTag.setAttribute("width", "100%");

      return iframeTag;
    }
  }

  static value(node) {
    if (node.getAttribute("title")) {
      return { src: node.getAttribute("src"), alt: node.getAttribute("title") };
    } else {
      return node.getAttribute("src");
    }
    // return { src: node.getAttribute('src'), alt: node.getAttribute('title') };
  }
}

VideoBlot.blotName = "video";
VideoBlot.tagName = "video";
Quill.register(VideoBlot);

var AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      body: "",
      files: [],
    };

    this.reactQuillRef = null;

    this.inputOpenImageRef = React.createRef();
    this.inputOpenVideoRef = React.createRef();

    this.rteChange = this.rteChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  imageHandler = () => {
    this.inputOpenImageRef.current.click();
  };

  videoHandler = () => {
    this.inputOpenVideoRef.current.click();
  };

  insertImage = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0];

      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);

      axios
        .post("/user/blog/image", formData, config)
        .then((response) => {
          if (response.status === 200) {
            const quill = this.reactQuillRef.getEditor();
            quill.focus();

            let range = quill.getSelection();
            let position = range ? range.index : 0;
            quill.insertEmbed(position, "image", {
              src: response.data.url,
              alt: response.data.fileName,
            });
            quill.setSelection(position + 1);

            if (this._isMounted) {
              this.setState(
                {
                  files: [...this.state.files, response.data.url],
                },
                () => {
                  this.props.onFilesChange(this.state.files);
                }
              );
            }
          } else {
            return alert("failed to upload file");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //Insert Video
  insertVideo = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0];

      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);

      axios
        .post("/user/blog/image", formData, config)
        .then((response) => {
          if (response.status === 200) {
            const quill = this.reactQuillRef.getEditor();
            quill.focus();

            let range = quill.getSelection();
            let position = range ? range.index : 0;
            quill.insertEmbed(position, "video", {
              src: response.data.url,
              alt: response.data.fileName,
            });
            quill.setSelection(position + 1);

            if (this._isMounted) {
              this.setState(
                {
                  files: [...this.state.files, response.data.url],
                },
                () => {
                  this.props.onFilesChange(this.state.files);
                }
              );
            }
          } else {
            return alert("failed to upload file");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  rteChange = (e) => {
    const newValue = e;
    console.log(newValue);
    this.props.onChange(newValue);
    this.setState({ body: newValue });
  };

  render() {
    return (
      <div>
        <div id="toolbar">
          <span class="ql-formats">
            <select class="ql-font">
              <option selected>Sans Serif</option>
              <option value="raleway">Raleway</option>
              <option value="josefinslab">JosefinSlab</option>
              <option value="nunitosans">NunitoSans</option>
            </select>
          </span>
          <select
            className="ql-header"
            defaultValue={""}
            onChange={(e) => e.persist()}
          >
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="4" />
            <option value="" />
          </select>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
          <button className="ql-insertImage">I</button>
          <button className="ql-insertVideo">V</button>
          <button className="ql-insertFile">F</button>
          <button className="ql-link" />
          <button className="ql-code-block" />
          <button className="ql-video" />
          <button className="ql-blockquote" />
          <button className="ql-clean" />
          <select class="ql-color">
            <option value="rgb(0, 0, 0)" />
            <option value="rgb(230, 0, 0)" />
            <option value="rgb(255, 153, 0)" />
            <option value="rgb(255, 255, 0)" />
            <option value="rgb(0, 138, 0)" />
            <option value="rgb(0, 102, 204)" />
            <option value="rgb(153, 51, 255)" />
            <option value="rgb(255, 255, 255)" />
            <option value="rgb(250, 204, 204)" />
            <option value="rgb(255, 235, 204)" />
            <option value="rgb(204, 224, 245)" />
            <option value="rgb(235, 214, 255)" />
            <option value="rgb(187, 187, 187)" />
            <option value="rgb(102, 185, 102)" />
          </select>
          <select
            title="Background Color"
            className="ql-background"
            defaultValue="rgb(255, 255, 255)"
          >
            <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" />
            <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)" />
            <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)" />
            <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)" />
            <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)" />
            <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)" />
            <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)" />
            <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)" />
            <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)" />
            <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)" />
            <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)" />
            <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)" />
            <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)" />
            <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)" />
            <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)" />
            <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)" />
            <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)" />
            <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)" />
            <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)" />
            <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)" />
            <option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)" />
            <option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)" />
            <option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)" />
            <option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)" />
            <option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)" />
            <option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)" />
            <option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)" />
            <option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)" />
            <option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)" />
            <option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)" />
            <option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)" />
            <option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)" />
            <option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)" />
            <option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)" />
            <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)" />
          </select>
          <span class="ql-formats">
            <button class="ql-align" value=""></button>
            <button class="ql-align" value="center"></button>
            <button class="ql-align" value="right"></button>
            <button class="ql-align" value="justify"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
        </div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme="snow"
          modules={this.modules}
          formats={this.formats}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.rteChange}
        />
        <input
          type="file"
          accept="image/*"
          ref={this.inputOpenImageRef}
          style={{ display: "none" }}
          onChange={this.insertImage}
        />
        <input
          type="file"
          accept="video/*"
          ref={this.inputOpenVideoRef}
          style={{ display: "none" }}
          onChange={this.insertVideo}
        />
      </div>
    );
  }
  modules = {
    //syntax: true,
    imageResize: {},
    toolbar: {
      container: "#toolbar",
      handlers: {
        insertImage: this.imageHandler,
        insertVideo: this.videoHandler,
      },
    },
  };

  formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "image",
    "video",
    "file",
    "link",
    "code-block",
    "video",
    "blockquote",
    "clean",
    "color",
    "background",
    "align",
    "list",
    "indent",
  ];
}

export default RichTextEditor;
