import React, { Fragment } from "react";
import PropTypes from "prop-types";
// MUI
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  ...theme.spreadThis,
  card: {
    position: "relative",
    float: "right",
    padding: "30px",
    marginTop: "30px",
    marginBottom: "30px",
    marginRight: "30px",
    marginLeft: "30px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    overflow: "hidden",
  },
  cardContent: {
    width: "90%",
    padding: "25px",
  },
  media: {
    height: 190,
  },
});

const GallerySkeleton = (props) => {
  const { classes } = props;

  const content = Array.from({ length: 4 }).map((item, index) => (
    <Card className={classes.card} key={index} elevation={0}>
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />

      <Skeleton animation="wave" variant="rect" className={classes.media} />

      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  ));

  return <Fragment>{content}</Fragment>;
};

GallerySkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GallerySkeleton);
