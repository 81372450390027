import React, { Component, Fragment } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//Material Ui
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Blog from "../components/blog/Blog";
import ScreamSkeleton from "../util/ScreamSkeleton";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import { getBlogs } from "../redux/actions/dataActions";
const styles = (theme) => ({
  ...theme.spreadThis,
  addButton: {
    top: 0,
    backgroundColor: "#fff",
    display: "flex",
    overflow: "hidden",
    float: "right",
    justifyContent: "flex-end",
  },
});

class blog extends Component {
  componentDidMount() {
    this.props.getBlogs();
  }
  render() {
    const { blogs, loading } = this.props.data;
    const { classes } = this.props;
    const {
      width,
      user: { authenticated },
    } = this.props;
    let recentBlogsMarkup = !loading ? (
      blogs.map((blog) => (
        <GridListTile
          style={{
            position: "relative",
            float: "left",
            padding: "30px",
            minHeight: "400px",
            overflow: "hidden",
            height: "100% !important",
          }}
          key={blog.blogId}
        >
          <Blog key={blog.blogId} blog={blog} />
        </GridListTile>
      ))
    ) : (
      <ScreamSkeleton />
    );
    let columns = width === "xs" || width === "sm" ? 1 : 2;
    return (
      <Fragment>
        {authenticated && (
          <div className={classes.addButton}>
            <div>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/blog/create"
              >
                New Post
              </Button>
            </div>
          </div>
        )}
        <Grid container spacing={4}>
          <Grid item sm={10} xs={12}>
            <GridList
              cols={columns}
              style={{
                width: "100%",
              }}
            >
              {recentBlogsMarkup}
            </GridList>
          </Grid>
          <Grid item sm={2} xs={12}>
            <div></div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

blog.propTypes = {
  user: PropTypes.object.isRequired,
  getBlogs: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
});

export default connect(mapStateToProps, { getBlogs })(
  compose(withStyles(styles), withWidth())(blog)
);
