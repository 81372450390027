import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import GallerySkeleton from "../util/GallerySkeleton";

//Material Ui
import Grid from "@material-ui/core/Grid";
import GalleryImage from "../components/gallery/GalleryImage";
import LatestBlog from "../components/blog/LatestBlog";
import GridList from "@material-ui/core/GridList";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

import { connect } from "react-redux";
import { getGalleryImages } from "../redux/actions/dataActions";
import { getLatestBlogs } from "../redux/actions/dataActions";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    height: "551",
    width: "100%",
  },
  latestblog: {
    marginTop: "30px",
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid #9c64fb",
    [theme.breakpoints.up("md")]: {
      marginRight: "0px",
      marginLeft: "0px",
    },
    marginRight: "30px",
    marginLeft: "30px",
    zIndex: 10,
  },
  Title: {
    textAlign: "center",
    padding: "10px 0 10px",
    fontSize: "16px",
    fontFamily: "Karla,sans-serif",
  },
});

class gallery extends Component {
  componentDidMount() {
    this.props.getGalleryImages();
    this.props.getLatestBlogs();
  }
  render() {
    const { latestblogs, images, loading } = this.props.data;
    const { classes, width } = this.props;

    let recentImagesMarkup = !loading ? (
      images.map((image, index) => (
        <GalleryImage key={image.imageId} image={image} index={index + 1} />
      ))
    ) : (
      <GallerySkeleton />
    );

    let latestBlogsMarkup = !loading
      ? latestblogs.map((blog, index) => (
          <LatestBlog key={blog.blogId} blog={blog} index={index + 1} />
        ))
      : "Loading...";
    let columns = width === "xs" || width === "sm" ? 1 : 2;
    let gridcolumnsgallery = width === "xs" || width === "sm" ? 12 : 10;
    let gridcolumnslatest = width === "xs" || width === "sm" ? 12 : 2;
    return (
      <Grid container spacing={0}>
        <Grid item sm={gridcolumnsgallery} xs={12}>
          <div className={classes.root}>
            <GridList className={classes.gridList} cols={columns}>
              {recentImagesMarkup}
            </GridList>
          </div>
        </Grid>

        <Grid item sm={gridcolumnslatest} xs={12}>
          <List
            className={classes.latestblog}
            subheader={
              <ListSubheader color={"primary"} className={classes.Title}>
                Latest Blogs
              </ListSubheader>
            }
          >
            {latestBlogsMarkup}
          </List>
        </Grid>
      </Grid>
    );
  }
}

gallery.propTypes = {
  getGalleryImages: PropTypes.func.isRequired,
  getLatestBlogs: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});
export default connect(mapStateToProps, { getGalleryImages, getLatestBlogs })(
  compose(withStyles(styles), withWidth())(gallery)
);
