import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dayjs from "dayjs";

//Mui stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  ...theme.spreadThis,
  commentImage: {
    maxWidth: "100%",
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
  commentData: {
    marginLeft: 50,
  },
  commentBy: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  invisibleSeparatorReply: {
    border: "none",
    margin: 10,
  },
});

class BlogReplies extends Component {
  render() {
    const { comments, classes } = this.props;
    return (
      <Grid container>
        {this.props.comments &&
          comments.map((comment, index) => {
            const { body, createdAt, name } = comment;
            return (
              <Fragment key={createdAt}>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={4}>
                    <Grid item sm={12} xs={12}>
                      <div className={classes.commentData}>
                        <Typography
                          className={classes.commentBy}
                          variant="subtitle2"
                          color="primary"
                        >
                          {name} SAYS...
                        </Typography>

                        <Typography variant="body1">{body}</Typography>
                        <hr className={classes.invisibleSeparator} />
                        <Typography variant="body2" color="textSecondary">
                          {dayjs(createdAt).format("h:mm a, MMMM DD YYYY")}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <hr className={classes.invisibleSeparatorReply} />
              </Fragment>
            );
          })}
      </Grid>
    );
  }
}

BlogReplies.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default withStyles(styles)(BlogReplies);
