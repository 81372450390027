//User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";

//UI reducer types

export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

//Data reducer types
export const SET_SCREAMS = "SET_SCREAMS";
export const SET_BLOGS = "SET_BLOGS";
export const SET_LATEST_BLOGS = "SET_LATEST_BLOGS";
export const SET_SCREAM = "SET_SCREAM";
export const SET_BLOG = "SET_BLOG";
export const LIKE_SCREAM = "LIKE_SCREAM";
export const UNLIKE_SCREAM = "UNLIKE_SCREAM";
export const DELETE_SCREAM = "DELETE_SCREAM";
export const DELETE_BLOG = "DELETE_BLOG";
export const POST_SCREAM = "POST_SCREAM";
export const POST_BLOG = "POST_BLOG";
export const POST_CONTACT = "POST_CONTACT";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const SUBMIT_BLOG_COMMENT = "SUBMIT_BLOG_COMMENT";
export const SUBMIT_BLOG_REPLY = "SUBMIT_BLOG_REPLY";
export const SET_GALLERY_IMAGES = "SET_GALLERY_IMAGES";
