import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import dayjs from "dayjs";

//Redux Stuff

import { connect } from "react-redux";
import BlogComments from "../components/blog/BlogComments";
import BlogCommentForm from "../components/blog/BlogCommentForm";
import { getBlog } from "../redux/actions/dataActions";
import Typography from "@material-ui/core/Typography";

//Mui imports
import withStyles from "@material-ui/core/styles/withStyles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const styles = (theme) => ({
  ...theme.spreadThis,
  postPage: {
    "& iframe ": {
      minHeight: "400px",
    },
    "& img ": {
      objectFit: "cover",
      height: "100%",
      backgroundColor: "#fff",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    margin: 10,
  },
  author: {
    display: "block",
    margin: "6px 0 5px 0",
    color: "rgba(0,0,0, 0.6)",
  },
  authorName: {
    color: "#E13D3D",
    fontSize: "1em",
  },
  postBlogButton: {
    position: "absolute",
    padding: "10px",
    cursor: "pointer",
    border: "1px solid #9c64fb",
    fontSize: "18px",
    "&:hover": {
      background: "#9c64fb",
      color: "#000",
    },
    color: "#9c64fb",
    background: "#fff",
  },
  commentsLink: {
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    fontSize: "18px",
    color: "#E13D3D",
    position: "relative",
    top: "2px",
    padding: "10px",
  },
  commentCount: {
    fontSize: "2.0rem",
    lineHeight: "2.8rem",
    fontWeight: 300,
    color: "#9c64fb",
  },
  heading2: {
    "& h2 ": {
      fontFamily:
        "Whitney SSm 4r,Whitney SSm A,Whitney SSm B,Helvetica Neue, Helvetica, Arial, sans-serif",
      fontSize: "4.0rem",
      lineHeight: "4.8rem",
      fontWeight: 400,
      textAlign: "left",
      margin: "0 0 2.4rem 0",
      padding: 0,
      clear: "both",
    },
  },
});

class blogPage extends Component {
  refA = React.createRef();
  refB = React.createRef();
  state = {
    openComment: true,
  };
  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleReplyChange = (someValue) => {
    this.setState({ openComment: someValue });
  };
  componentDidMount() {
    const blogId = this.props.match.params.blogId;
    this.props.getBlog(blogId);
  }
  render() {
    const {
      classes,
      blog: {
        blogId,
        body,
        createdAt,
        userHandle,
        title,
        comments,
        commentCount,
      },
      UI: { loading },
    } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item sm={10} xs={12}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div
              className={classes.postPage}
              style={{ width: "90%", margin: "3rem auto" }}
            >
              <h2 className={classes.heading2}>{title}</h2>
              <div className={classes.author}>
                <span>posted by </span>
                <span className={classes.authorName}>{userHandle}</span>
                <span>
                  &nbsp;
                  <AccessTimeIcon fontSize="inherit" />{" "}
                  {dayjs(createdAt).format("MMM DD YYYY")}
                </span>
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: body }} />
              <br />
              <div className={classes.postBlogButton}>
                <Typography
                  onClick={() => {
                    this.handleScrollTo(this.refA);
                  }}
                  variant="h6"
                  color="secondary"
                >
                  post a comment
                </Typography>
              </div>
              <div className={classes.commentsLink}>
                <Typography variant="h6" color="secondary">
                  Comments{" "}
                  <span className={classes.commentCount}>{commentCount}</span>
                </Typography>
              </div>
              <div ref={this.refB}>
                <BlogComments
                  commentHandler={this.handleReplyChange}
                  comments={comments}
                />
              </div>
              <br />
              <div ref={this.refA}>
                <BlogCommentForm
                  commentMainHandler={this.handleReplyChange}
                  blogId={blogId}
                  refB={this.refB}
                />
              </div>
            </div>
          )}
        </Grid>
        <Grid item sm={2} xs={2}>
          <div></div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  blog: state.data.blog,
  UI: state.UI,
});

const mapActionsToProps = {
  getBlog,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(blogPage));
